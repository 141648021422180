<template>
    <div style="display: flex;font-size:12px">
        <el-form :model="ruleForm" ref="ruleForm">
            <el-row style="width: 100%;">
                <el-col :span="5">
                    <label>{{ $t("common.province") }}:</label>
                    <el-select filterable :clearable="true" v-model="ruleForm.province" style="margin-left: 10px;width:70%" :placeholder="$t('common.pleaseselectprovince')" size="small" @change="getCityData">
                        <el-option
                            v-for="item in addressArray"
                            :key="item.code"
                            :label="item.cnName"
                            :value="item.code"
                        >
                        </el-option>
                    </el-select>
                </el-col>
                <el-col :span="5">
                    <label>{{ $t("common.city") }}:</label>
                    <el-select filterable :clearable="true" v-model="ruleForm.city" style="flex: 1;margin-left: 10px;width:70%" :placeholder="$t('common.pleaseselectcity')" size="small"
                            @change="getAreaData">
                        <el-option
                        v-for="item in cityArray"
                        :key="item.code"
                        :label="item.cnName"
                        :value="item.code">
                        </el-option>
                    </el-select>
                </el-col>
                <el-col :span="5">
                    <label for="">{{ $t("common.district") }}:</label>
                    <el-select filterable :clearable="true" v-model="ruleForm.district" style="flex: 1;margin-left: 10px;width:70%" :placeholder="$t('common.pleaseselectdistrict')" size="small"
                        @change="onAreaChanged">
                        <el-option
                            v-for="item in areaArray"
                            :key="item.code"
                            :label="item.cnName"
                            :value="item.code">
                        </el-option>
                    </el-select>
                </el-col>
                <el-col :span="6" :style="nameStyle">
                    <label for="">{{name}}:</label>
                    <el-input v-model="ruleForm.parameter" style="flex: 1;margin-left: 10px;width:62%" :placeholder="$t('common.pleaseinput')" size="small">
                    </el-input>
                </el-col>
                <el-col :span="3">
                    <el-button type="primary" size="small" class="fc-label" style="width: 40%" @click="searchSite">{{ $t("common.search") }}</el-button>
                    <el-button type="primary" size="small" plain @click="resetForm('ruleForm')" style="width: 40%">{{ $t("common.clear") }}</el-button>
                </el-col>
            </el-row>
        </el-form>
    </div>
</template>

<script>
  import api from "@/api/index.js";
  // 使用说明：v-model时，必须传带有带有省，市，区拼音的字段
  export default {
    name: "FilterCondition",
    //通过 model 选项配置子组件接收的 prop 名以及派发的事件名
    model: {
      prop: 'caddress',
      event: 'change',
    },
    props: {
      caddress: {
        type: Object,
      },
      name: {
        type: String
      }
    },
    data() {
      return {
        addressArray: [],//所有数据
        cityArray: [],
        areaArray: [],
        ruleForm: {
          province: "",
          city: "",
          district: "",
          code: "",
          areaCode: ""
        },
        nameStyle: {
            visibility : "hidden" //visible
        }
      }
    },
    created() {
        this.ruleForm = this.caddress;
        this.getAddressData();
        this.nameStyle.visibility = (!!this.name? "visible" : "hidden");
    },
    methods: {
      /** 省市区查询
       * JoinCode：上级编码
       * TypeID： 101 省， 102 市， 103 区
       */
      SearchSmartDataarea(JoinCode, TypeID, List){
        api.searchSmartDataarea({
          JoinCode,
          TypeID
        }).then(res => {
          if(res.code == 200) {
            List = res.data;
          }
        });
      },
      getAddressData() {
        var that = this;
        //this.SearchSmartDataarea("0", 101, this.addressArray);
        api.searchSmartDataarea({
          JoinCode: "0",
          TypeID: 101
        }).then(res => {
          if(res.code == 200) {
            this.addressArray = res.data;
          }
        });
        
      },
      //选择省份
      getCityData(val) {
        //清空市，和区
        this.ruleForm.city='';
        this.ruleForm.district='';
        this.areaArray = [];
        this.cityArray = [];
        //设置城市code
        this.ruleForm.areaCode = val;
        this.$emit('change', this.ruleForm);//发送改变
        if(val != "") {
          api.searchSmartDataarea({
            JoinCode: val,
            TypeID: 102
          }).then(res => {
            if(res.code == 200) {
              this.cityArray = res.data;
            }
          });
        }
      },
      getAreaData(val) {
        //清空区
        this.ruleForm.district='';
        this.areaArray = [];
        //设置城市code
        this.ruleForm.areaCode = val;
        this.$emit('change', this.ruleForm);//发送改变
        if(val != "") {
          api.searchSmartDataarea({
            JoinCode: val,
            TypeID: 103
          }).then(res => {
            if(res.code == 200) {
              this.areaArray = res.data;
            }
          });
        }
      },
      //地区数据变动后
      onAreaChanged(val) {
        //设置城市code
        this.ruleForm.areaCode = val;
        this.$emit('change', this.ruleForm);//发送改变
      },
      //搜索
      searchSite() {
        var index = 0
        this.$emit("search-site", this.ruleForm);
      },
      //重置表单
      resetForm(formName) {
        //this.$refs[formName].resetFields();
        this.ruleForm = {
          province: "",
          city: "",
          district: "",
          parameter: "",
          code: "",
          areaCode: ""
        }
      }
    }
  }
</script>

<style scoped>
.fc-label{
    margin-left: 15px; 
}

</style>