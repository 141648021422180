<template>
    <el-dialog :visible.sync="visible" :show-close="false" custom-class="ppDetail" @close="closePPDetail">
        <span slot="title" class="pp-title">
            <el-row>
                <el-col :span="4">
                    <strong>竞品详情</strong>
                </el-col>
                <el-col :span="20" style="text-align:right;">
                    <!-- <el-link type="primary" icon="el-icon-s-home" :underline="false">
                        <span style="font-size:10px">转门店</span>
                    </el-link> -->
                    <el-link style="margin-left: 10px;" type="primary" icon="el-icon-upload" :underline="false">
                        <span style="font-size:12px">导出报告</span>
                    </el-link>
                </el-col>
            </el-row>
        </span>
        <div class="pp-main">
            <el-form :model="ruleForm" ref="ruleForm" label-width="100px" class="demo-ruleForm" size="small">
                <el-row>
                    <div ref="newPPMap" id="pp" style="width: 100%;height: 250px;margin-bottom:10px;"></div>
                </el-row>
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="省份:">
                            {{ruleForm.provinceName}}
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="城市:">
                            {{ruleForm.cityName}}
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="区县:">
                            {{ruleForm.districtName}}
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col>
                        <el-form-item label="详细地址:">
                            <el-input v-model="ruleForm.address" placeholder="请输入"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="品牌:">
                            <el-select v-model="ruleForm.mainBrand" placeholder="请选择">
                                <el-option v-for="item in brandList" :key="item.id" :label="item.type_Name" :value="item.dict_Code"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="门店名称:">
                            <el-input v-model="ruleForm.cnName" placeholder="请输入"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="编号:">
                            <el-input v-model="ruleForm.code" placeholder="请输入"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="门店电话:">
                            <el-input v-model="ruleForm.phone" placeholder="请输入"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </div>
        <span slot="footer">
            <el-button @click="closePPDetail" style="width:130px;" size="medium" ><span style="font-size:12px;">取消</span></el-button>
            <el-button type="primary" @click="saveVSInfo" style="width:130px;" size="medium" ><span style="font-size:12px;">保存</span></el-button>
        </span>
    </el-dialog>
</template>
<script>
import mapboxgl from "mapbox-gl";
import api from "@/api/index.js";
export default {
    name: "ListVSDetail",
    props: ["vsVisible"],
    data(){
        return{
            visible: false,
            ruleForm: {
                longitude: "",
                latitude: "",
                province: "",
                city: "",
                district: "",
                mainBrand: "",
                code: "",
                openData: "",
                cnName: "",
                enName: "",
                creator: "",
                createDate: "",
                address: "",
                status: "",
                provinceName: "",
                cityName: "",
                districtName: "",
                d4:""
            },
            map:"",
            marker: "",
            markList: [
                {
                    name: "机会点",
                    type: "2"
                }, {
                    name: "门店",
                    type: "3"
                }, {
                    name:"商圈",
                    type: "4"
                }, 
            ],
            brandList: [], // 品牌
        }
    },
    mounted(){
        this.visible = this.vsVisible;
        setTimeout(()=>{
            if(this.visible==true){
                this.initMap();
            }
        },0);
    },
    methods:{
        initMap(){
            var lat = sessionStorage.getItem("lat");
            var lng = sessionStorage.getItem("lng");
            
            if(!lng || !lat){
                lat = "31.312276";
                lng = "121.490371";
            }
            mapboxgl.accessToken = "pk.eyJ1IjoibWFvcmV5IiwiYSI6ImNqNWhrenIwcDFvbXUyd3I2bTJxYzZ4em8ifQ.KHZIehQuWW9AsMaGtATdwA";
            this.map = new mapboxgl.Map({
                container: this.$refs.newPPMap,
                style:{
                    "version": 8,
                    "name": "Mapbox Streets",
                    "sprite": "mapbox://sprites/mapbox/streets-v8",
                    "glyphs": "mapbox://fonts/mapbox/{fontstack}/{range}.pbf",
                    "sources": {
                        "osm-tiles": {
                            "type": "raster",
                            "tiles": ["https://wprd03.is.autonavi.com/appmaptile?lang=zh_cn&size=1&style=7&x={x}&y={y}&z={z}"],
                            "tileSize": 128
                        }
                    },
                    "layers":[{
                        "id": "main",
                        "type": "raster",
                        "source": "osm-tiles",
                        "source-layer": "osmtiles"
                    }]
                },
                center: [lng, lat], // 初始定位坐标 [lng, lat]
                zoom: 12, // 初始缩放大小
                maxZoom: 18,
                // maxBounds: bounds, // 设置地图范围   
                doubleClickZoom: false,// 禁止双击缩放
                localIdeographFontFamily: "'Noto Sans', 'Noto Sans CJK SC', sans-serif" //字体
            });
            this.map.loadImage(require('../../assets/images/marker/红.png'), (error, image) => {
                if (error) throw error
                // 先判断是否加载了该 id 的图片资源，没有则加载
                if (!this.map.hasImage('store')) {
                this.map.addImage('store', image)
                }}
            )
            //添加门店/机会点/竞品等图片
            this.map.loadImage(require('../../assets/images/marker/jihuidian.png'), (error, image) => {
                if (error) throw error;
                // 先判断是否加载了该 id 的图片资源，没有则加载
                if (!this.map.hasImage('mcd')) {
                    this.map.addImage('mcd', image);
                }}
            )
            //kfc
            this.map.loadImage(require('../../assets/images/marker/kfc.png'), (error, image) => {
                if (error) throw error
                // 先判断是否加载了该 id 的图片资源，没有则加载
                if (!this.map.hasImage('kfc')) {
                    this.map.addImage('kfc', image);
                }}
            )
            //burgerking 
            this.map.loadImage(require('../../assets/images/marker/burgerking.png'), (error, image) => {
                if (error) throw error
                // 先判断是否加载了该 id 的图片资源，没有则加载
                if (!this.map.hasImage('burgerking')) {
                    this.map.addImage('burgerking', image);
                }}
            )
            //dicos
            this.map.loadImage(require('../../assets/images/marker/dicos.png'), (error, image) => {
                if (error) throw error
                // 先判断是否加载了该 id 的图片资源，没有则加载
                if (!this.map.hasImage('dicos')) {
                    this.map.addImage('dicos', image)
                }}
            )
            //dmno
            this.map.loadImage(require('../../assets/images/marker/dmno.png'), (error, image) => {
                if (error) throw error
                // 先判断是否加载了该 id 的图片资源，没有则加载
                if (!this.map.hasImage('dmno')) {
                    this.map.addImage('dmno', image)
                }}
            )
            //pht
            this.map.loadImage(require('../../assets/images/marker/pht.png'), (error, image) => {
                if (error) throw error
                // 先判断是否加载了该 id 的图片资源，没有则加载
                if (!this.map.hasImage('pht')) {
                    this.map.addImage('pht', image)
                }}
            )
            //ppj
            this.map.loadImage(require('../../assets/images/marker/ppj.png'), (error, image) => {
                if (error) throw error
                // 先判断是否加载了该 id 的图片资源，没有则加载
                if (!this.map.hasImage('ppj')) {
                    this.map.addImage('ppj', image)
                }}
            )
            //subway
            this.map.loadImage(require('../../assets/images/marker/subway.png'), (error, image) => {
                if (error) throw error
                // 先判断是否加载了该 id 的图片资源，没有则加载
                if (!this.map.hasImage('subway')) {
                    this.map.addImage('subway', image)
                }}
            )
            //mendian
            this.map.loadImage(require('../../assets/images/marker/mendian.png'), (error, image) => {
                if (error) throw error
                // 先判断是否加载了该 id 的图片资源，没有则加载
                if (!this.map.hasImage('mendian')) {
                    this.map.addImage('mendian', image)
                }}
            )
            //jhd
            this.map.loadImage(require('../../assets/images/marker/jihuidian.png'), (error, image) => {
                if (error) throw error
                // 先判断是否加载了该 id 的图片资源，没有则加载
                if (!this.map.hasImage('jihuidian')) {
                    this.map.addImage('jihuidian', image)
                }}
            );
            this.getCompetitorBrand();
            var that = this;
            this.map.on("load",() => {
                that.map.getCanvas().style.cursor = "crosshair";
                that.requestMarker();
                that.map.on("dragend", e => {
                    that.requestMarker(this.legendType);
                });
                that.map.on("zoomend", e => {
                    that.requestMarker(this.legendType);
                })
               // that.map.on("click", e => {
                    that.addPointToMap(this.legendType);
               // })
            });
        },
        //地图上添加标注点
        addPointToMap(){
            var that = this;
            var geojson = {
                "type": "FeatureCollection",
                "features": []
                };
            that.map.on("click",(e) => {
                if(that.marker != "")
                that.marker.remove();
                
                var point = [{
                "type": "Feature",
                "geometry": {
                    "type": "Point",
                    "coordinates": [
                    e.lngLat.lng,
                    e.lngLat.lat
                    ]
                },
                "properties": {
                    "marker-color": "#7e7e7e",
                    "marker-size": "medium",
                    "marker-symbol": "",
                }
                }];
                geojson.features = point;
                var el = document.createElement('div');
                el.className = 'jingpin';
                var el1 = document.createElement('p');
                el.appendChild(el1); 
                var el2 = document.createElement('span');
                el1.appendChild(el2); 
                that.marker = new mapboxgl.Marker(el,{offset: [0, -18]})
                .setLngLat(e.lngLat)
                .addTo(that.map);
                api.initVSInfo({
                    cusNo: sessionStorage.cusNo,
                    id: 0,
                    latitude: e.lngLat.lat,
                    longitude: e.lngLat.lng
                }).then(res => {
                    if(res.code == 200) {
                        that.ruleForm = res.data.competitor;
                        that.ruleForm.longitude = e.lngLat.lng;
                        that.ruleForm.latitude = e.lngLat.lat;
                        this.brandList = res.data.brandList;
                    }
                });
            });
        },
        closePPDetail(){
            this.visible = false;
            this.$emit("update-vsvisible",this.visible);
        },
        //保存信息
        saveVSInfo(){
            if(this.ruleForm.openData != "") {
                this.ruleForm.openData = this.$Format("yyyy-MM-dd",this.ruleForm.openData);
            }
            this.ruleForm.creator = sessionStorage.getItem("userName");
            this.ruleForm.createDate = this.$Format("yyyy-MM-dd",new Date());
            this.ruleForm.cusNo = sessionStorage.cusNo;
            api.saveCompetitorInfo(this.ruleForm).then(res => {
                if(res.code == 200) {
                    this.closePPDetail();
                } else {
                    this.$message.error("保存失败");
                }
            }).catch(err => {
                console.error(err);
                this.$message.error("接口请求报错");
            });
        },
        //请求Marker接口
        requestMarker(){
            var that = this;
            var nw = this.map.getBounds().getNorthWest();
            var se = this.map.getBounds().getSouthEast();
            var ne = this.map.getBounds().getNorthEast();
            var sw = this.map.getBounds().getSouthWest();
            var latitude, minimumLatitude,longitude,minimumLongitude;
            latitude = Math.max(nw.lat, se.lat);
            minimumLatitude = Math.min(nw.lat, se.lat);
            longitude = Math.max(nw.lng, se.lng);
            minimumLongitude = Math.min(nw.lng, se.lng);
            var options = {
                latitude,
                longitude,
                minimumLatitude,
                minimumLongitude,
                cusNo: sessionStorage.cusNo,
                geometry: `[[${nw.lng},${nw.lat}],[${ne.lng},${ne.lat}],[${se.lng},${se.lat}],[${sw.lng},${sw.lat}]]`,
                city: sessionStorage.currentCity
            };
            this.markList.map( item => {
                let type = item.type, name = item.logo, brandId = item.brandid;
                options.type = type;
                if(type == "1"){
                    options.brandId = brandId;
                }
                api.renderMarker(options).then(res => {
                if(res.code == 200 && res.data.length > 0){
                var data, that = this;
                data = res.data;
                if(type == "1") {
                    var feas = [];
                    data.map(m => {
                    feas.push({
                        type: "Feature",
                        properties: {
                        id:m.id,
                        name: m.sitename,
                        avg:10,
                        icon: name,//应该根据品牌icon品牌
                        type:m.sitetype,
                        },
                        geometry: {
                        type: "Point",
                        coordinates: [m.longitude,m.latitude]
                        }
                    });
                    });
                    that.drawVSPoint(feas, name);
                } else if(type == "2") {
                    var feas = [];
                    data.map(m => {
                    feas.push({
                        type: "Feature",
                        properties: {
                        id:m.id,
                        name: m.sitename,
                        avg:10,
                        icon: "mcd",//应该根据品牌icon品牌
                        type:m.sitetype,
                        },
                        geometry: {
                        type: "Point",
                        coordinates: [m.longitude,m.latitude]
                        }
                    });
                    });
                    that.drawPPPoint(feas);
                } else if(type == "3") {
                    var feas = [];
                    data.map(m => {
                    feas.push({
                        type: "Feature",
                        properties: {
                        id:m.id,
                        name: m.storename,
                        avg:10,
                        icon: "store",//应该根据品牌icon品牌
                        type:m.storetype,
                        phone: m.phone,
                        datas: m
                        },
                        geometry: {
                        type: "Point",
                        coordinates: [m.longitude,m.latitude]
                        }
                    });
                    });
                    that.drawStorePoint(feas);
                } else if(type == "4") {
                    var feas = [];
                    data.map(m => {
                    feas.push({
                        type: "Feature",
                        properties: {
                        "taid": m.id,
                        "name": m.cnName,
                        "geometry": m.geometry
                        },
                        geometry: {
                        type: "Polygon",
                        coordinates: that.$arrayStringToArray(m.geometry)
                        }
                        } 
                    );
                    });
                    that.drawTAPolygon(feas);
                } else {
                }
                }
            }).catch(err => {
                console.log(err);
            })
            });
            
        },
        //绘制机会点
        drawPPPoint(feas){
            var geojsonPoints={
                type: "FeatureCollection",
                features: []
            };
            geojsonPoints.features=feas;
            if (this.map.getLayer('pplayer')) {
                this.map.getSource('geodataPPPoint').setData(geojsonPoints);
            } else {
                this.map.addSource('geodataPPPoint', { 
                type: 'geojson', 
                data: geojsonPoints,
                cluster: true,    //允许聚合
                clusterMaxZoom: 10,
                clusterRadius: 50 
                });
                // this.map.addLayer({
                //     id: "ppClusters",
                //     type: "circle",
                //     source: "geodataPPPoint",
                //     filter: ["has", "point_count"],
                //     paint: {
                //         "circle-color": [
                //         "step",
                //         ["get", "point_count"],
                //         "#f28cb1",
                //         100,
                //         "#f1f075",
                //         750,
                //         "#51bbd6"
                //         ],
                //         "circle-radius": [
                //         "step",
                //         ["get", "point_count"],
                //         20,
                //         150,
                //         30,
                //         750,
                //         40
                //         ]
                //     }
                // });
                // this.map.addLayer({
                //     id: "ppCluster-count",
                //     type: "symbol",
                //     source: "geodataPPPoint",
                //     filter: ["has", "point_count"],
                //     layout: {
                //         "text-field": "{point_count_abbreviated}",
                //         "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
                //         "text-size": 12
                //     },
                //     paint: {
                //         "text-color": "#ffffff"
                //     }
                // });
                this.map.addLayer({
                    "id": "pplayer",
                    "type": "symbol",
                    "source": "geodataPPPoint",
                    "layout": {
                        "icon-image": ["get", "icon"],
                        "icon-size": 1,
                        "icon-allow-overlap": true
                    }
                });
                // this.map.addLayer({
                //     "id": "pplayer-highlighted",
                //     "type": "circle",
                //     "source": "geodataPPPoint",
                //     "source-layer": "original",
                //     "paint": {
                //         "circle-outline-color": "#484896",
                //         "circle-color": "#6e599f",
                //         "circle-opacity": 0.75
                //     },
                //     "filter": ["in", "id", ""]
                // })
            }
        
        },
        //绘制门店点
        drawStorePoint(feas){
            var geojsonPoints = {
                type: "FeatureCollection",
                features: []
            };
            geojsonPoints.features = feas;
            if (this.map.getLayer('storelayer')) {
                this.map.getSource('geodataStorePoint').setData(geojsonPoints);
            } else {
                this.map.addSource('geodataStorePoint', { 
                type: 'geojson', 
                data: geojsonPoints,
                cluster: true,
                clusterMaxZoom: 10,
                clusterRadius: 50
                });
                this.map.addLayer({
                id: "storeClusters",
                type: "circle",
                source: "geodataStorePoint",
                filter: ["has", "point_count"],
                paint: {
                    "circle-color": [
                    "step",
                    ["get", "point_count"],
                    "#6b73d7",
                    100,
                    "#f1f075",
                    750,
                    "#f28cb1"
                    ],
                    "circle-radius": [
                    "step",
                    ["get", "point_count"],
                    20,
                    150,
                    30,
                    750,
                    40
                    ]
                }
                });
                this.map.addLayer({
                id: "storeCluster-count",
                type: "symbol",
                source: "geodataStorePoint",
                filter: ["has", "point_count"],
                layout: {
                    "text-field": "{point_count_abbreviated}",
                    "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
                    "text-size": 12,
                },
                paint: {
                    "text-color": "#ffffff"
                }
                });
                this.map.addLayer({
                "id": "storelayer",
                "type": "symbol",
                "source": "geodataStorePoint",
                "filter": ["!", ["has", "point_count"]],
                "layout": {
                    "icon-image": ["get", "icon"],
                    "icon-size": 1,
                    "icon-allow-overlap": true
                }
                });
            }
        
        },
        //绘制竞品点
        drawVSPoint(feas, name){
            var geojsonPoints = {
                type: "FeatureCollection",
                features: []
            };
            geojsonPoints.features = feas;
            let layer = name + "layer"; //point 图层
            let clusters = name + "Clusters"; //聚合图层
            let clusterCount = name + "Cluster-count"; //聚合数量图层
            let source = name + "Source"; //数据源
            if (this.map.getLayer(layer)) {
                this.map.getSource(source).setData(geojsonPoints);
            } else {
                this.map.addSource(source, { 
                type: 'geojson', 
                data: geojsonPoints,
                cluster: true,
                clusterMaxZoom: 10,
                clusterRadius: 50
                });
                this.map.addLayer({
                id: clusters,
                type: "circle",
                source: source,
                filter: ["has", "point_count"],
                paint: {
                    "circle-color": [
                    "step",
                    ["get", "point_count"],
                    "#f1f075",
                    100,
                    "#51bbd6",
                    750,
                    "#f28cb1"
                    ],
                    "circle-radius": [
                    "step",
                    ["get", "point_count"],
                    20,
                    150,
                    30,
                    750,
                    40
                    ]
                }
                });
                this.map.addLayer({
                id: clusterCount,
                type: "symbol",
                source: source,
                filter: ["has", "point_count"],
                layout: {
                    "text-field": "{point_count_abbreviated}",
                    "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
                    "text-size": 12
                },
                paint: {
                    "text-color": "#ffffff"
                }
                });
                this.map.addLayer({
                "id": layer,
                "type": "symbol",
                "source": source,
                "filter": ["!", ["has", "point_count"]],
                "layout": {
                    "icon-image": ["get", "icon"],
                    "icon-size": 1,
                    "icon-allow-overlap": true
                }
                });
            }
        },
        //绘制商圈
        drawTAPolygon(feas) {
            var geojsonPolygon = {                  /* geojson数据 */
                type: "FeatureCollection",
                features: feas
            }
            if (this.map.getLayer('maine')) {
                this.map.getSource('maines').setData(geojsonPolygon);
            } else {
                this.map.addSource('maines', {       /* 添加Source，类型是geojson */
                    type: 'geojson',
                    data: {                  /* geojson数据 */
                        type: "FeatureCollection",
                        features: []
                }
                });
                this.map.addLayer({
                    'id': 'maine',
                    'type': 'fill',            /* fill类型layer */
                    'source': 'maines',         
                    'layout': {},
                    'paint': {
                        'fill-color': '#0a38f1',  /* fill颜色 */
                        'fill-opacity': 0.6    /* fill透明度 */
                    }
                });
            }
        },
        //快速定位到当前位置
        goLocation(){
            var that = this;
            //调用高德web服务api获取当前位置ip
            this.$http.get(constants.iMap.GEOURL,{params: {
                address: localStorage.cityname,
                key: constants.iMap.WEBKEY
            }}).then(res => {
                if(res.status == 200) {
                var crdStr = res.data.geocodes[0].location;
                var crd = crdStr.split(",");
                that.map.flyTo({
                    center: crd, // 中心点
                    zoom: 11 // 缩放比例
                })
                } else {
                that.$message({
                message: `ERROR(${res.data.infocode}): ${res.data.info})`,
                type: 'warning'
                })
                }
            }).catch(err => {
                that.$message({
                message: `ERROR(${err.msg})`,
                type: 'warning'
                })
            })
        },
        //获取竞品品牌
        getCompetitorBrand(){
            api.getCompetitorBrand({
                cusNo: sessionStorage.cusNo
            }).then(res => {
            if(res.code == 200 || res.code == 0){
                if(res.data.length > 0){
                res.data.map((m, i) => {
                    var item = {
                    type: "1",
                    brandid: m.dict_Code,
                    logo: m.logo.slice(13).split(".")[0]
                    };
                    item.name = this.$i18n.locale == "zh"?m.type_Name : m.type_EnName; //选择语言
                    this.markList.splice(4+i,0,item); // 将竞品插入在指定位置
                });
                }
            }
            }).catch(err => {
            console.log(err);
            });
        },
    },
    watch:{
        vsVisible(val){
            this.visible = val;
            if(val==true)
                setTimeout(()=>{
                    this.initMap();
                },0);
        }
    }
}
</script>
<style>
.ppDetail{
    font-size: 14px;
    border-radius: 5px; 
}
.ppDetail .el-dialog__header{
    background: #eee;
    padding: 18px 20px;
    border-top-left-radius: 5px; 
    border-top-right-radius: 5px; 
}
/* .pp-main{
    max-height: 350px;
    overflow-y: scroll;
} */
.pp-main::-webkit-scrollbar {
    display: none;
}
.pp-main .el-divider--horizontal{
    margin-top: 12px; 
}
.pp-main .el-form-item__label{
    font-size: 11px;
}
.pp-main .el-form-item__content{
    font-size: 11px;
}
/* .pp-main .el-form-item--small.el-form-item{
    margin-bottom: 7px;
} */
.pp-partIcon{
    width: 5px;
    height: 5px;
    border-radius: 2.5px;
    background: #0188e2;
    display: inline-block;
    margin: 3px 5px;
}
.ppDetail .el-dialog__footer{
    box-shadow: 0 0 10px #ddd;
}
.jingpin {
  display: block;
  border: none;
  cursor: pointer;
  padding: 0;
  background: url("../../assets/images/marker/jingpin.png") no-repeat;
  background-size: 100% 100%;
  width:26px;
  height:38px;
  padding-left: 5px;
}
</style>